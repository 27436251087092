<template>
	<div class="mx-5">
		<!-- This example requires Tailwind CSS v2.0+ -->
		<div class="container flex flex-col m-auto">
			<div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
				<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
					<TFileReader
						:upload="sourcesUpload"
						:count="sourcesUploadCounter"
						:error="sourcesUploadError"
						@load="upload"
					/>
					<h3 class="mt-10 mb-5 text-lg font-medium text-gray-900 leading-6">
						{{ $i18n("Sources") }} {{ $i18n("feeds") }} - {{ feedName }}
					</h3>

					<DataFilter>
						<template #create>
							<router-link
								:to="{ path: `/sources/${feedId}/source/create` }"
								class="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-green-600 border border-l-0 border-transparent rounded rounded-l-none shadow-sm rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
							>
								{{ $i18n("Create") }}
							</router-link>
						</template>
					</DataFilter>

					<Loader v-if="sourcesLoad" />
					<div class="flex">
						<t-pagination
							v-if="!sourcesLoad && sources.data.length"
							v-model="currentPage"
							:per-page="sourcesLimit"
							:total-items="sources.meta.results_count"
							variant="rounded"
							class="mx-auto mb-5"
						/>
					</div>
					<div
						v-if="!sourcesLoad"
						class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
					>
						<table class="min-w-full divide-y divide-gray-200">
							<thead class="bg-gray-50">
								<tr>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Name") }}
									</th>
									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Created at") }}
									</th>

									<th
										scope="col"
										class="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
									>
										{{ $i18n("Updated at") }}
									</th>
									<th scope="col" class="relative px-6 py-3"></th>
								</tr>
							</thead>
							<tbody class="bg-white divide-y divide-gray-200">
								<tr
									v-for="{
										id,
										name,
										type,
										feed_id,
										external_id,
										created_at,
										updated_at,
									} in sources.data"
									:key="id"
								>
									<td class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4 break-all">
												<span class="text-sm">
													<LazyIcon :type="type" class="inline" />
												</span>
												<router-link
													:to="{
														path: `/feed/view/${feed_id}?source_group_id=${external_id}`,
													}"
													class="text-sm text-gray-500"
												>
													{{ name }}
												</router-link>
											</div>
										</div>
									</td>
									<td class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500">
													{{ formatDate({ date: created_at }) }}
												</div>
											</div>
										</div>
									</td>

									<td class="py-4 pr-6 whitespace-nowrap">
										<div class="flex items-center">
											<div class="ml-4">
												<div class="text-sm text-gray-500">
													{{ formatDate({ date: updated_at }) }}
												</div>
											</div>
										</div>
									</td>
									<td class="py-4 pr-6 text-sm font-medium text-right whitespace-nowrap">
										<div
											@click="deleteSourceByIdMethod({ sourceId: id, name })"
											class="text-red-600 cursor-pointer hover:text-red-900"
										>
											<svg
												xmlns="http://www.w3.org/2000/svg"
												class="inline w-6 h-6"
												fill="none"
												viewBox="0 0 24 24"
												stroke="currentColor"
											>
												<path
													stroke-linecap="round"
													stroke-linejoin="round"
													stroke-width="2"
													d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
												/>
											</svg>
										</div>
									</td>
								</tr>

								<!-- More source... -->
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<t-pagination
				v-if="!sourcesLoad && sources.data.length"
				v-model="currentPage"
				:per-page="sourcesLimit"
				:total-items="sources.meta.results_count"
				variant="rounded"
				class="mt-5"
			/>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";
import DataFilter from "@/components/DataFilter";
import LazyIcon from "@/components/LazyIcon";
import FileReader from "@/components/FileReader";

export default {
	name: "Sources",
	components: {
		Loader,
		DataFilter,
		LazyIcon,
		TFileReader: FileReader,
	},
	props: {
		feedId: {
			type: String,
		},
		page: {
			type: String,
			default: "1",
		},
	},
	data() {
		return {
			feedName: "",
		};
	},
	computed: {
		...mapGetters("sources", [
			"sources",
			"sourcesLimit",
			"sourcesLoad",
			"sourcesUploadError",
			"sourcesUploadCounter",
			"sourcesUpload",
		]),
		currentPage: {
			get() {
				return Number(this.page);
			},
			async set(page) {
				try {
					const { $route, $router } = this;
					const {
						$route: { params },
					} = this;
					const route = {
						...$route,
						params: { ...params, page: String(page) },
					};
					await $router.push(route);
					await this.fetch();
				} catch (error) {
					console.debug(error);
				}
			},
		},
	},
	methods: {
		...mapActions("sources", [
			"fetchSources",
			"deleteSourceById",
			"uploadSources",
		]),
		...mapActions("feeds", ["fetchFeedById"]),
		async deleteSourceByIdMethod({ sourceId, name }) {
			try {
				const {
					feedId,
					currentPage,
					$router,
					$dialog: { confirm },
					$i18n,
					fetch,
				} = this;

				const { isOk } = await confirm({
					clickToClose: false,
					escToClose: false,
					title: $i18n("Delete") + " " + name + " ?",
					icon: "info",
					cancelButtonText: $i18n("Cancel"),
					okButtonText: $i18n("Do"),
				});
				if (isOk) {
					await this.deleteSourceById({ feedId, sourceId });

					const resultsCount = this.keywords.data.length;

					if (currentPage !== 1 && !resultsCount) {
						await $router.replace({
							...this.$route,
							params: { ...this.$route.params, page: this.$route.params.page - 1 },
						});
					}
					await fetch();
				}
			} catch (error) {
				console.debug(error);
			}
		},
		async fetch({ withPage = true } = {}) {
			try {
				const {
					feedId,
					page,
					$route: { query },
				} = this;

				await this.fetchSources({
					feedId,
					page: withPage ? page : undefined,
					query,
				});
			} catch (error) {
				console.debug(error);
			}
		},
		async fetchFeedName() {
			try {
				const { name } = await this.fetchFeedById(this.feedId);
				this.feedName = name;
			} catch (error) {
				console.debug(error);
			}
		},
		formatDate({ date }) {
			const dateInstance = new Date(date);
			return (
				dateInstance.toLocaleDateString() + " " + dateInstance.toLocaleTimeString()
			);
		},
		async upload(sources) {
			try {
				const { feedId } = this;
				await this.uploadSources({ feedId, sources });
				await this.fetch();
			} catch (error) {
				console.debug(error);
			}
		},
	},
	async created() {
		try {
			this.$on("Filter", async () => {
				await this.fetch({ withPage: false });
			});
			await this.fetch();
			await this.fetchFeedName();
		} catch (error) {
			console.debug(error);
		}
	},
};
</script>
