<template>
	<div>
		<label class="block text-sm font-medium text-gray-700">
			{{ $i18n("File") }}
		</label>
		<div
			@dragover.prevent
			@dragleave.prevent
			@drop.prevent="drop"
			class="flex justify-center px-6 pt-5 pb-6 mt-1 border-2 border-gray-300 border-dashed rounded-md"
		>
			<div class="text-center space-y-1">
				<template v-if="upload">
					<Loader class="mb-5" />
				</template>
				<template v-else>
					<div class="flex text-sm text-gray-600">
						<label
							for="file-upload"
							class="relative font-medium text-blue-600 bg-white cursor-pointer rounded-md hover:text-blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-blue-500"
						>
							<span>{{ $i18n("Upload a file") }}</span>
							<input
								@change="load"
								id="file-upload"
								name="file-upload"
								type="file"
								class="sr-only"
							/>
						</label>
						<p class="pl-1">{{ $i18n("or drag and drop") }}</p>
					</div>
					<p class="text-xs text-gray-500">
						TXT, CSV
					</p>
				</template>
				<div class="pt-5 text-xs text-gray-500">
					{{ $i18n("Uploaded strings") }}: {{ count }}
				</div>
				<template v-if="error.length">
					<div
						@click="toggleError"
						class="pt-5 text-xs text-red-500 underline cursor-pointer"
					>
						{{ $i18n("Not uploaded strings") }}: {{ error.length }}
					</div>
					<div v-if="showError" class="pt-3">
						<p
							v-for="(value, index) in error"
							:key="index"
							class="text-xs text-gray-500"
						>
							{{ value }}
						</p>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import Loader from "@/components/Loader";

export default {
	name: "FileReader",
	props: {
		upload: {
			type: Boolean,
			default: false,
		},
		count: {
			type: Number,
			default: 0,
		},
		error: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			showError: false,
		};
	},
	components: {
		Loader,
	},
	methods: {
		isTrueType({ type }) {
			if (!["text/plain", "text/csv"].includes(type)) {
				throw "File type error";
			}
		},
		drop(event) {
			try {
				const { loadText } = this;
				const {
					dataTransfer: { files },
				} = event;
				this.isTrueType(files[0]);
				loadText(files[0]);
			} catch (error) {
				console.debug(error);
			}
		},
		load(event) {
			try {
				const { loadText } = this;
				const {
					target: { files },
				} = event;
				this.isTrueType(files[0]);

				loadText(files[0]);
			} catch (error) {
				console.debug(error);
			}
		},
		loadText(file) {
			try {
				const reader = new FileReader();

				reader.onload = (event) => {
					const {
						target: { result },
					} = event;
					this.$emit("load", result.split(/\n/));
				};
				reader.readAsText(file);
			} catch (error) {
				console.debug(error);
			}
		},
		toggleError() {
			try {
				this.showError = !this.showError;
			} catch (error) {
				console.debug(error);
			}
		},
	},
};
</script>
